<template>
  <div id="data-list-thumb-view" class="data-list-container" @keydown.esc="addNewDataSidebar = false">
    <vs-popup title="Are you sure you want to delete all your notification?" :active.sync="popupActive">
      <div class="flex">
        <vs-button color="danger" class="vx-col lg:w-1/4" @click="deleteAllNotifications">Delete</vs-button>
        <vs-button color="grey" class="vx-col lg:w-1/4 ml-3" @click="popupActive = false">Cancel</vs-button>
      </div>
    </vs-popup>
    <vs-table
      v-if="notifications.length !== 0"
      ref="table"
      no-data-text=""
      pagination
      :max-items="itemsPerPage"
      search
      :data="notifications"
    >
      <div slot="header" class="lg:w-auto w-full">
        <div class="lg:w-auto w-full mb-5 lg:mb-0 lg:pr-4">
          <vs-button color="grey" class="pr-6 pl-4 mr-8 w-full" icon="delete" @click="initDeleteAll">Clear notifications</vs-button>
        </div>
      </div>

      <template slot="thead">
        <vs-th></vs-th>
        <vs-th sort-key="jobData.jobTitle">Job title</vs-th>
        <vs-th sort-key="jobData.jobTitle">Applicant name</vs-th>
        <vs-th sort-key="type">Type</vs-th>
        <vs-th sort-key="updatedAt">Notification date</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody v-if="data">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
            class="cursor-default"
            :data="tr"
            :class="data[indextr].expired ? 'expired' : ''"
          >
            <vs-td class="img-container">
              <img
                :src="data[indextr].jobData.jobImage"
                :data="data[indextr].jobData.jobImage"
                class="product-img"
                :class="data[indextr].expired ? 'expired_image' : ''"
              />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium" :data="data[indextr].jobData.jobTitle">
                {{ data[indextr].jobData.jobTitle }}
              </p>
            </vs-td>
            <vs-td v-if="data[indextr].userData">
              <p class="product-name font-medium" :data="data[indextr].userData.userName">
                {{ data[indextr].userData.userName }}
              </p>
            </vs-td>

            <vs-td v-else> N / A</vs-td>

            <vs-td>
              <p
                :class="
                  data[indextr].type === 'Job Expired' || data[indextr].type === 'Job Expiring' || data[indextr].type === 'deactivated'
                    ? 'text-danger'
                    : 'text-primary'
                "
                class="product-category"
                ><span v-if="data[indextr].type === 'accepted'">Invite </span
                >{{ data[indextr].type.charAt(0).toUpperCase() + data[indextr].type.slice(1) }}</p
              >
            </vs-td>
            <vs-td>
              <p class="product-category">
                {{ data[indextr].createdAt | formatSeconds }}
              </p>
            </vs-td>
            <vs-td>
              <div class="flex">
                <div>
                  <vs-dropdown class="cursor-pointer" :vs-trigger-click="true">
                    <vs-icon size="large" icon="more_vert"></vs-icon>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="handleNtfRoute(data[indextr].type, tr)">
                        <div class="flex items-center">
                          <vs-icon class="mr-3" icon="visibility"></vs-icon>
                          <p>View</p>
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="deleteNotification(tr)">
                        <div class="flex items-center">
                          <vs-icon class="mr-3" icon="delete"></vs-icon>
                          <p>Delete</p>
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <div
      v-if="notifications.length === 0"
      class="px-24 py-32 rounded-lg bg-white h-10 border-solid border-2 border-grey-light min-w-full flex-col flex items-center justify-center"
    >
      <div>
        <p class="text-center font-bold text-lg"> No New Notifications </p>
        <p class="text-center text-grey"> You will receive notifications when guides interact with your job posts</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: {},
      select: false,
      jobs: [],
      itemsPerPage: 5,
      isMounted: false,
      addNewDataSidebar: false,
      editCheck: false,
      popupActive: false,
    };
  },
  computed: {
    notifications() {
      const obj = this.$store.state.notificationModule.data;
      let notiArr = [];
      Object.keys(obj).map((key) => {
        notiArr.push(obj[key]);
      });

      notiArr.sort(function (a, b) {
        return b.createdAt.seconds - a.createdAt.seconds;
      });
      return notiArr;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
  },

  destroyed: function () {
    document.removeEventListener('keyup', this.myMethod);
  },
  created() {
    document.addEventListener('keyup', this.myMethod);
    this.refreshData();
  },

  mounted() {
    this.isMounted = true;
  },
  methods: {
    async deleteAllNotifications() {
      let ntfIds = [];
      this.notifications.forEach((ntf) => {
        ntfIds.push(ntf.id);
      });

      await this.$store.dispatch('notificationModule/deleteBatch', ntfIds);
      this.popupActive = false;
    },
    initDeleteAll() {
      this.popupActive = true;
    },
    handleNtfRoute(type, user) {
      switch (type) {
        case 'applied':
          if (this.$route.name !== 'ApplicationIn') {
            this.$router.push({
              name: 'ApplicationIn',
              params: { userToView: user.userId },
            });
          }
          break;
        case 'accepted':
          if (this.$route.name !== 'ApplicationIn') {
            this.$router.push({
              name: 'ApplicationIn',
              params: { userToView: user.userId },
            });
          }
          break;
        case 'New Message':
          if (this.$route.name !== 'chat') {
            this.$router.push({
              name: 'chat',
            });
          }
          break;
        case 'Job Expired':
          if (this.$route.name !== 'Archived Jobs') {
            this.$router.push({
              name: 'Archived Jobs',
            });
          }
          break;
        default:
          this.$router.push({
            name: 'Jobs',
          });
      }
    },
    toNotificationExpired() {
      this.$router.push({
        name: 'Archived Jobs',
      });
    },
    deleteNotification(notification) {
      // this.notifications.splice(this.notifications.indexOf(notification), 1);
      this.$store.dispatch('notificationModule/delete', notification.id);
    },
    myMethod(event) {
      if (event.keyCode === 27) {
        this.addNewDataSidebar = false;
      }
    },
    refreshData() {
      if (this.$store.state.notificationModule.getNotifications) {
        this.notifications = this.formatData(this.$store.state.notificationModule.getNotifications);
        this.notifications.sort((x, y) => {
          let timeX = x.expire_at;
          let timeY = y.expire_at;
          return timeY - timeX;
        });
      }
    },
    async handleSelected(tr) {
      this.selected = tr;
      this.select = true;
    },
    getOrderStatusColor(status) {
      if (status == 'on hold') return 'warning';
      if (status == 'delivered') return 'success';
      if (status == 'canceled') return 'danger';
      return 'primary';
    },
    getPopularityColor(num) {
      if (num > 90) return 'primary';
      if (num > 70) return 'primary';
      if (num >= 50) return 'warning';
      if (num < 50) return 'danger';
      return 'primary';
    },
    formatData(data) {
      // formats data received from API
      let formattedData = Object.values(data).map(function (key) {
        const fields = key;
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] = fields[key];
        }

        return obj;
      });
      this.$vs.loading.close();
      return formattedData;
    },
  },
};
</script>

<style lang="scss">
.expired {
  -webkit-transform: translate(0) !important;
  transform: translate(0) !important;
  background: rgba(var(--vs-danger), 0.05) !important;
  color: rgba(var(--vs-danger), 1);
  -webkit-box-shadow: 0 0 1px 0 rgba(var(--vs-danger), 1) !important;
  box-shadow: 0 0 1px 0 rgba(var(--vs-danger), 1) !important;
}

.expired_image {
  filter: opacity(0.5) drop-shadow(0 0 0 red);
}

.multiselect__tag {
  background: #adca63 !important;
}

.multiselect__option--highlight {
  background: #adca63 !important;
}
#data-list-thumb-view {
  .vs-con-table {
    .vs-table--tr .is-selected {
      background-color: black !important;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .con-input-search {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
      }

      .vs-table--search-input {
        width: 100% !important;
        border-radius: 5px !important;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: center;
            }

            .product-img {
              height: 110px;
              width: 100%;
              object-fit: cover;
              border-radius: 8px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

@media (min-width: 992px) {
  #data-list-thumb-view {
    .vs-con-table {
      .vs-table--header {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
